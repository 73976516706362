import React, { useContext, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import '../assets/scss/pages/registration-form.scss';
import '../assets/scss/pages/user-verify.scss';
import { Context } from '../index';
import LayoutForm from '../components/user/LayoutForm';
import AdminService from '../services/AdminService';
import { INDEX_ROUTE } from '../utils/consts';
import { useNavigate } from 'react-router-dom';

const UserVerifyMessage = () => {
  const { userStore } = useContext(Context);
  const [message, setMessage] = useState({});
  const history = useNavigate();
  const msgRef = useRef(null);

  useEffect(() => {
    if (!localStorage.getItem('tokenUser')) {
      history(INDEX_ROUTE);
    }
  }, [userStore.isAuth, userStore.isLoading, userStore.user]);

  const resendEmail = async () => {
    const msgElement = msgRef.current;
    try {
      await AdminService.resendEmailActivateLink(userStore.user.email).then((response) => {
        if (response) {
          msgElement.classList.remove('is-invalid');
          msgElement.classList.add('is-valid');
          setMessage({ success: response.data.success });
        }
      });
    } catch (e) {
      msgElement.classList.remove('is-valid');
      msgElement.classList.add('is-invalid');
      setMessage({ error: e.response?.data?.message });
    }
  };
  return (
    <LayoutForm classSelector={'user-verify'} title={'Verify Email Address'}>
      <span>
        We’ve sent a verification email to <b>{userStore.user.email}</b>.
      </span>
      <br />
      <span>Click on the link in the email to continue signing up.</span>
      <div className={'block-button'}>
        <button type="button" className={'btn btn-grey'} disabled={true}>
          Next
        </button>
      </div>

      <div className={'block-message'}>
        <div ref={msgRef}></div>
        <div className="invalid-feedback">{message.error}</div>
        {message.success && <div className="valid-feedback">{message.success}</div>}
      </div>
      <div className="bottom-info">
        <span className="sc-r">Didn’t receive an email?</span>{' '}
        <a href={'#'} onClick={resendEmail}>
          Send again
        </a>
        <br />
        <div className="sub-text">
          <span className="sc-r">Still not received? Please, be sure that you entered a correct</span>{' '}
          <b className="sc-r">email address</b> <span className="sc-r">or try later</span>
        </div>
      </div>
    </LayoutForm>
  );
};

export default observer(UserVerifyMessage);
