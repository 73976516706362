import React, { Fragment, useContext, useRef, useState } from 'react';
import { Context } from '../index';
import { observer } from 'mobx-react-lite';
import '../assets/scss/pages/registration-form.scss';
import '../assets/scss/pages/pin-form.scss';
import ModalDialogBoxContent from '../components/user/ModalDialogBoxContent';
import InputPin from '../components/user/InputPin';
import $ from 'jquery';
import AuthUserService from '../services/AuthUserService';
import { Link, useNavigate } from 'react-router-dom';
import { LOGIN_ROUTE, USER_MESSAGE_ADDED_PIN_ROUTE } from '../utils/consts';
import LayoutForm from '../components/user/LayoutForm';
import ModalDialogFaq from '../components/user/ModalDialogFaq';
import ModalDialogSupport from '../components/user/ModalDialogSupport';

const UserAddPin = () => {
  const { userStore } = useContext(Context);
  const history = useNavigate();
  const [errors, setErrors] = useState([]);
  const user = userStore.user;
  const [numChildren, setNumChildren] = useState(0);
  const errorRef = useRef(null);
  const children = [];

  const removeComponent = (e, number) => {
    e.preventDefault();
    $(e.target)
      .parents('#input_ornament_' + number)
      .remove();
  };

  for (let i = 0; i < numChildren; i++) {
    children.push(<InputPin key={i + 1} number={i + 1} removeComponent={removeComponent} />);
  }

  const executeScroll = () => errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

  const addComponent = (e) => {
    e.preventDefault();
    setNumChildren((count) => count + 1);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const arr = [];
    const pinArr = [];
    let iter = 0;
    let pinStr = '';
    let nameStr = '';

    if ($(form).find('input.is-invalid').length === 0) {
      formData.forEach((value) => {
        arr.push(value);
        iter++;
        if (iter <= 2) {
          if (iter === 1) {
            pinStr = value.toUpperCase();
          } else {
            nameStr = value;
          }
          if (iter === 2) {
            pinArr.push({ pin: pinStr, name: nameStr });
            iter = 0;
          }
        }
      });

      const valueArr = pinArr.map(function (item) {
        return item.pin;
      });
      const findDuplicates = (arr) => arr.filter((item, index) => arr.indexOf(item) !== index);

      if (findDuplicates(valueArr).length === 0) {
        try {
          await AuthUserService.activatePinStatus(
            user.id,
            user.email,
            user.marketingEmail,
            pinArr,
            navigator.userAgent,
          ).then((response) => {
            if (response?.error) {
              setErrors(response.error);
              executeScroll();
            } else {
              setErrors([]);
              const countPins = pinArr.length + userStore.user.pins.rows.length;
              history(USER_MESSAGE_ADDED_PIN_ROUTE + '?count=' + countPins);
            }
          });
        } catch (e) {
          if (e.response?.data?.errors[0] === 'user_error') {
            userStore.isAuth = false;
            localStorage.removeItem('tokenUser');
            history(LOGIN_ROUTE);
          }
          setErrors(e.response?.data?.errors);
          executeScroll();
          return { error: true, message: e.response?.data?.errors };
        }
      } else {
        const uniqueArr = [...new Set(findDuplicates(valueArr))];
        const pinList = uniqueArr.toString().replaceAll(',', ', ');
        setErrors([`The PIN codes entered must be unique (${pinList}).`]);
        executeScroll();
      }
    }
  };

  const handleClick = () => {
    history(-1);
  };

  return (
    <LayoutForm classSelector={'pin-form'} title={'Add ornament'}>
      <span>Add another ornament to your account.</span>
      <div className="error-info sc-r" ref={errorRef}>
        {errors &&
          errors.map(
            (message, index) =>
              message && (
                <Fragment key={index}>
                  {message}
                  <br />
                </Fragment>
              ),
          )}
      </div>

      <form action="" method="" onSubmit={handleSubmit}>
        <InputPin number={0} />
        {children}

        <div className="block-add-component">
          <span>Have multiple ornaments?</span>
          <a href="#" onClick={addComponent}>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 6.75H6.75V12H5.25V6.75H0V5.25H5.25V0H6.75V5.25H12V6.75Z" fill="#006548" />
            </svg>
            Add another ornament
          </a>
        </div>

        <div className="block-info">
          <strong className="sc-r">Can’t find your PIN?</strong>
          <a href="#" data-bs-toggle="modal" data-bs-target={'#modalFAQ'}>
            View FAQ
          </a>
          <a href="#" data-bs-toggle="modal" data-bs-target={'#modalSupport'}>
            Contact Support
          </a>
        </div>

        <button type="submit" className={'btn btn-red'}>
          Next
        </button>
        <div className="text-center my-3">
          <p className="h5">OR</p>
        </div>
        <div className="text-center">
          <Link className="h5" onClick={handleClick}>
            Back to Sign In
          </Link>
        </div>
      </form>
      <ModalDialogBoxContent title={'Frequently Asked Questions'} modalId={'modalFAQ'} classSelector={'full-height'}>
        <ModalDialogFaq />
      </ModalDialogBoxContent>
      <ModalDialogBoxContent title={'Support'} modalId={'modalSupport'}>
        <ModalDialogSupport />
      </ModalDialogBoxContent>
    </LayoutForm>
  );
};

export default observer(UserAddPin);
